import React from 'react';
import { Result } from 'antd';

export default () => {

    return (
        <div style={{ width: '100%' }}>
            <Result
                status="success"
                title="Todos os Lançamentos foram contabilizados com sucesso"
                subTitle="Com o SePlaneje é facil, :)"

            />
        </div >
    )
}